import { useSmallerScreen } from 'hooks'
import React from 'react'
import { Anchor, Group, Image, Stack, Text } from '@mantine/core'

import boyAvatar from 'assets/images/site/avatar/boy.png'
import girl10Avatar from 'assets/images/site/avatar/girl10.png'

const Footer = () => {
  const smallerScreen = useSmallerScreen()
  const Beian = () => {
    return (
      <Anchor
        size="xs"
        color="gray.5"
        href="https://beian.miit.gov.cn/"
        underline={false}
        target="_blank"
        className="hover:underline"
      >
        陕ICP备19008575号-5
      </Anchor>
    )
  }

  return (
    <Stack spacing={smallerScreen ? 29 : 6} align="center" mb={smallerScreen ? 25 : 22}>
      <Group spacing={smallerScreen ? 'xs' : 'lg'}>
        <Image src={boyAvatar.src} alt="avatar" width={smallerScreen ? 60 : 100} height={smallerScreen ? 60 : 100} />
        <Anchor
          className="hover:underline"
          underline={false}
          size="md"
          color="gray.7"
          href="https://howxm.com/help/articles/product-story"
          target="_blank"
        >
          ©️2024 金数据团队，骄傲出品
        </Anchor>
        <Image src={girl10Avatar.src} alt="avatar" width={smallerScreen ? 60 : 100} height={smallerScreen ? 60 : 100} />
      </Group>
      {smallerScreen ? (
        <Stack spacing={0} align="center">
          <Text color="gray.5" size="xs">
            浩客XM@2024 | 西安数据如金信息科技有限公司 |{' '}
          </Text>
          <Text color="gray.5" size="xs">
            <Beian />
          </Text>
        </Stack>
      ) : (
        <Text color="gray.5" size="xs">
          浩客XM@2024 | 西安数据如金信息科技有限公司 | <Beian />
        </Text>
      )}
    </Stack>
  )
}

export default Footer
